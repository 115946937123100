const React = require('react')

const { PersistedElementProvider } = require('./src/components/context/PersistedElementContext')
const { StoreProvider } = require('./src/components/context/store-context')
const { getScriptURL } = require('./src/components/utilities/klaviyo-form')

exports.wrapRootElement = ({ element }) => {
  /*
  // Test forms
  WAC8gi - contact
  UuM6X8 - referral
  XNV7DB - sign up popup

  // Live forms
  TZUzrb - contact
  TgVycs - referral
  T5Nfx9 - sign up popup

  */

  // Check the hostname - if it contains meati.com then use the live forms
  // This allows us to test entries and not log false views/conversion rates on the live forms
  const { hostname } = window.location
  const isLive = hostname.includes('meati.com')

  let element_map = {}
  if (isLive) {
    // use live forms
    element_map = {
      kvform_TZUzrb: '<div class="klaviyo-form-TZUzrb"></div>',
      kvform_TgVycs: '<div class="klaviyo-form-TgVycs"></div>',
      kvform_T5Nfx9: '<div class="klaviyo-form-T5Nfx9"></div>',
      kvform_T7dMkQ: '<div class="klaviyo-form-T7dMkQ"></div>', // biggame
      kvform_VGjqnk: '<div class="klaviyo-form-VGjqnk"></div>', // expo west
      kvform_XGg4Kz: '<div class="klaviyo-form-XGg4Kz"></div>', // sxsw
      kvform_TK4Q9h: '<div class="klaviyo-form-TK4Q9h"></div>', // nra
      kvform_YAWPFC: '<div class="klaviyo-form-YAWPFC"></div>', // ghost opt in
      kvform_REjaYD: '<div class="klaviyo-form-REjaYD"></div>', // Rachael Ray
      
    }
  } else {
    // use test forms
    element_map = {
      kvform_WAC8gi: '<div class="klaviyo-form-WAC8gi"></div>',
      kvform_UuM6X8: '<div class="klaviyo-form-UuM6X8"></div>',
      kvform_XNV7DB: '<div class="klaviyo-form-XNV7DB"></div>',
      kvform_R9F5CN: '<div class="klaviyo-form-R9F5CN"></div>',
      kvform_T7dMkQ: '<div class="klaviyo-form-T7dMkQ"></div>', // biggame
      kvform_VGjqnk: '<div class="klaviyo-form-VGjqnk"></div>', // expo west
      kvform_XGg4Kz: '<div class="klaviyo-form-XGg4Kz"></div>', // sxsw
      kvform_TK4Q9h: '<div class="klaviyo-form-TK4Q9h"></div>', // nra
      kvform_YAWPFC: '<div class="klaviyo-form-YAWPFC"></div>', // ghost opt in
      kvform_REjaYD: '<div class="klaviyo-form-REjaYD"></div>', // Rachael Ray
    }
  }

  const func_after_setup = () => {
    // now that necessary DOM elements are guaranteed to exist, load+run external script
    const elem_script = document.createElement('script')
    elem_script.type = 'text/javascript'
    elem_script.src = getScriptURL()

    // !! 'head' vs 'body' ?
    document.head.appendChild(elem_script)

    // NOTE: should be OK that script runs _whenever_ after this point (during any part of render cycle):
    // - element is _always_ mounted somewhere in the DOM, so script should "see" it in any location

    // do the same thing for sparkloop script...
    // now that necessary DOM elements are guaranteed to exist, load+run external script
    /*
    const sl_script = document.createElement('script')
    sl_script.type = 'text/javascript'
    sl_script.src = 'https://dash.sparkloop.app/widget/MF976ab2c70617/embed.js'
    sl_script.setAttribute('data', 'sparkloop')

    // !! 'head' vs 'body' ?
    document.head.appendChild(sl_script)
    */
  }

  return (

    <PersistedElementProvider element_map={element_map} func_after_setup={func_after_setup}>
      <StoreProvider>
        {element}
      </StoreProvider>
    </PersistedElementProvider>
  )
}

import * as React from 'react'
import fetch from 'isomorphic-fetch'
import Client from 'shopify-buy'
import EventEmitter from '../utilities/event-emitter'

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch,
)

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  getItemQuantity: () => {},
  getProduct: () => {},
  removeLineItemFromLocalStorage: () => {},
  client,
  checkout: {
    lineItems: [],
  },
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== 'undefined'
const localStorageKey = 'shopify_checkout_id'
const localStorageKeyMaxItems = 'shopify_max_items'

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [loading, setLoading] = React.useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
      if (localStorage.getItem(localStorageKeyMaxItems) === null) {
        localStorage.setItem(localStorageKeyMaxItems, JSON.stringify([]))
      }
    }

    setCheckout(checkout)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== 'null') {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID,
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        addLineItemsToLocalStorage(lineItemsToUpdate)
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 3000)
        EventEmitter.emit('OpenMiniCart', {})
      })
  }

  const addLineItemsToLocalStorage = (items) => {
    const lsItems = JSON.parse(localStorage.getItem(localStorageKeyMaxItems))
    if (lsItems.length === 0) {
      lsItems.push(items[0])
      localStorage.setItem(localStorageKeyMaxItems, JSON.stringify(lsItems))
    } else {
      const indexPosition = lsItems.findIndex((x) => x.variantId === items[0].variantId)
      if (indexPosition !== -1) {
        lsItems[indexPosition].quantity = lsItems[indexPosition].quantity + items[0].quantity
      } else {
        lsItems.push(items[0])
      }
      localStorage.setItem(localStorageKeyMaxItems, JSON.stringify(lsItems))
    }
  }

  const updatelineItemsToLocalStorage = (items) => {
    const lsItems = JSON.parse(localStorage.getItem(localStorageKeyMaxItems))
    const indexPosition = lsItems.findIndex((x) => x.variantId === items[0].variantId)
    lsItems[indexPosition].quantity = items[0].quantity
    localStorage.setItem(localStorageKeyMaxItems, JSON.stringify(lsItems))
  }

  const removeLineItemFromLocalStorage = (variantId) => {
    const lsItems = JSON.parse(localStorage.getItem(localStorageKeyMaxItems))
    const indexPosition = lsItems.findIndex((x) => x.variantId === variantId)
    lsItems.splice(indexPosition, 1)
    localStorage.setItem(localStorageKeyMaxItems, JSON.stringify(lsItems))
  }

  const removeLineItem = (checkoutID, lineItemID, variantId) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res) => {
        removeLineItemFromLocalStorage(variantId)
        setCheckout(res)
        setLoading(false)
      })
  }

  const getProduct = (productId) => client.product.fetch(productId)

  const getItemQuantity = (variantId) => {
    const lsItems = JSON.parse(localStorage.getItem(localStorageKeyMaxItems))
    const indexPosition = lsItems.findIndex((x) => x.variantId === variantId)

    if (indexPosition === -1) return 0
    return lsItems[indexPosition].quantity
  }

  const updateLineItem = (checkoutID, lineItemID, quantity, variantID) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    const lineItemsToUpdateByVariant = [
      { variantId: variantID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        updatelineItemsToLocalStorage(lineItemsToUpdateByVariant)
        setCheckout(res)
        setLoading(false)
        EventEmitter.emit('OpenMiniCart', {})
      })
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        getProduct,
        getItemQuantity,
        removeLineItemFromLocalStorage,
        checkout,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
